<template>
    <div class="c-button" :class="bClass" @click="onClick"><slot></slot></div>
</template>

<script>
export default {
    name:"c-button",
    props:{
        bClass:{
            type:String,
            default:''
        }
    },
    methods:{
        onClick(){
            this.$emit('btnClick')
        }
    }
}
</script>

<style>
.c-button{width:100%; height:46px; border-radius: 27px; background: #F85659; color: #fff;font-size: 16px; text-align: center; line-height: 46px}
</style>